import {Injectable, EventEmitter} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Router} from "@angular/router";
import {BehaviorSubject, Observable, pipe, of} from "rxjs";
import {environment} from "@environments/environment";
import {User, Theme} from "@user/user.interface";
import {catchError, map, tap} from "rxjs/operators";
import moment from "moment-timezone";
import {ExternalProfileInfo} from "./auth.interface";

@Injectable({
  providedIn: "root",
})
export class AuthenticationService {
  public onUserLogOut = new EventEmitter<void>();
  public onUserLoggedIn: BehaviorSubject<User>;

  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;
  private storageKey = "currentUser";

  private intervalToCheckToken;

  constructor(
    private http: HttpClient,
    private router: Router,
  ) {
    const user: User = JSON.parse(localStorage.getItem(this.storageKey));
    this.currentUserSubject = new BehaviorSubject<User>(user);

    this.onUserLoggedIn = new BehaviorSubject<User>(user);

    if (user != null) {
      moment.tz.setDefault(user.TimezoneId);
    }

    this.currentUser = this.currentUserSubject.asObservable();

    this.currentUserSubject.subscribe((user) => {
      if (this.intervalToCheckToken != null) {
        clearInterval(this.intervalToCheckToken);
      }

      if (user == null) return;

      if (
        this.CheckIfTokenIsExpired() ||
        (user.SupportSessionExpirationDate && this.CheckIfSupportSessionIsExpired())
      ) {
        this.logout();
      }

      this.intervalToCheckToken = setInterval(() => this.HandleTokenExpiration(user.TokenExpirationDate), 30000);
    });
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  public get token(): string {
    if (this.currentUserValue) {
      return this.currentUserValue.Token;
    }
  }

  public updateUserWithRefreshedToken(token: string) {
    const user = this.currentUserSubject.getValue();
    user.Token = token;
    this.localSaveUser(user);
  }

  public hasGroupRole(roleName: string): boolean {
    const user = this.currentUserSubject.getValue();

    if (user == null) {
      return false;
    }

    if (user.IsSoleOwner) {
      return true;
    }

    let retVal = false;

    user.UserGroupRoles.forEach((ugr) => {
      ugr.GroupRole.Permissions.array.forEach((p) => {
        if (p.Equals(roleName)) {
          retVal = true;
        }
      });
    });

    return retVal;
  }

  refreshToken() {
    const user = this.currentUserSubject.getValue();

    if (user == null) return;

    return this.http
      .post<any>(`${environment.api}/refreshToken`, {
        Token: user.RefreshToken,
      })
      .subscribe(
        (x) => {
          (user.TokenExpirationDate = x.expirationDate), (user.Token = x.token);
          user.RefreshToken = x.refresh_token;
          this.localSaveUser(user);
        },
        (error) => console.log("Could not refresh the token.", error),
      );
  }

  updateTimezone(timezoneId: string, timezoneName: string) {
    const user = this.currentUserSubject.getValue();
    user.TimezoneId = timezoneId;

    user.TimezoneName = timezoneName;
    this.localSaveUser(user);
  }

  updateTheme(theme: Theme) {
    const user = this.currentUserSubject.getValue();
    user.Theme = theme;
    this.localSaveUser(user);
  }

  twoFactorLoginWithAuthenticator(tfaAccessKey: string, tfaCode: string) {
    return this.http
      .post<User>(`${environment.api}/login/two-factor-authenticator-login`, {
        TfaAccessKey: tfaAccessKey,
        TfaCode: tfaCode,
      })
      .pipe(
        tap((user) => {
          console.log("Something failed: ", user);
          if (user.IsEngagementChannelNew || user.EngagementChannelInstallError) {
            localStorage.removeItem("channelEngagementTmpKey");
          }
        }),
        this.saveUser,
        tap((user) => {
          this.onUserLoggedIn.next(user);
        }),
      );
  }

  twoFactorLoginWithRecoveryKey(tfaAccessKey: string, tfaCode: string) {
    return this.http
      .post<User>(`${environment.api}/login/two-factor-recovery-login`, {
        TfaAccessKey: tfaAccessKey,
        TfaCode: tfaCode,
      })
      .pipe(
        tap((user) => {
          console.log("Something failed: ", user);
          if (user.IsEngagementChannelNew || user.EngagementChannelInstallError) {
            localStorage.removeItem("channelEngagementTmpKey");
          }
        }),
        this.saveUser,
        tap((user) => {
          this.onUserLoggedIn.next(user);
        }),
      );
  }

  login(
    email: string,
    password: string,
    redirect_uri: string = null,
    state: string = null,
    client_id: string = null,
    response_type: string = null,
  ): Observable<User> {
    var loginObservable = this.loginWithoutSavingToken(email, password, redirect_uri, state, client_id, response_type);

    return loginObservable
      .pipe(
        map((user) => {
          if (user?.TfaAccessKey != null)
            throw new TwoFactorNeededError("Two factor needed", {tfaAccessKey: user.TfaAccessKey});

          return user;
        }),
      )
      .pipe(this.saveUser)
      .pipe(
        tap((user) => {
          this.onUserLoggedIn.next(user);
        }),
      );
  }

  loginWithoutSavingToken(
    email: string,
    password: string,
    redirect_uri: string = null,
    state: string = null,
    client_id: string = null,
    response_type: string = null,
  ): Observable<User> {
    const channelEngagementTmpId = localStorage.getItem("channelEngagementTmpKey");

    return this.http
      .post<User>(`${environment.api}/login`, {
        email,
        password,
        redirect_uri,
        state,
        client_id,
        response_type,
        ChannelEngagementTmpId: channelEngagementTmpId,
      })
      .pipe(
        tap((user) => {
          if (user.IsEngagementChannelNew || user.EngagementChannelInstallError) {
            localStorage.removeItem("channelEngagementTmpKey");
          }
        }),
      );
  }

  loginWithSupportSession(supportSessionToken: string, adminPassword: string): Observable<User> {
    return this.http
      .post<User>(`${environment.api}/supportsession/loginWithSupportSession`, {
        SupportSessionToken: supportSessionToken,
        AdminPassword: adminPassword,
      })
      .pipe(
        tap((user: User) => {
          this.clearAuthenticationData();
          localStorage.setItem(this.storageKey, JSON.stringify(user));
          this.currentUserSubject.next(user);
          this.currentUser = this.currentUserSubject.asObservable();
          this.onUserLoggedIn.next(user);
          moment.tz.setDefault(user.TimezoneId);
        }),
      );
  }

  register(registrationData: any): Observable<User> {
    return this.http.post<User>(`${environment.api}/register`, registrationData).pipe(
      tap((user) => {
        if (user.EmailConfirmed) {
          this.saveUser(of(user));
        }
      }),
    );
  }

  passwordReset(email: string): Observable<boolean> {
    return this.http.post<any>(`${environment.api}/resetPassword`, {email});
  }

  changePassword(userId: string, token: string, newPassword: string): Observable<User> {
    return this.http
      .put<User>(`${environment.api}/resetPassword`, {
        UserId: userId,
        Token: token,
        Password: newPassword,
      })
      .pipe(this.saveUser);
  }

  checkIfResetPasswordTokenIsValid(userId: string, token: string): Observable<boolean> {
    return this.http.get<boolean>(`${environment.api}/resetPassword`, {
      params: {
        token: token,
        userId: userId,
      },
    });
  }

  confirmEmail(userId: string, token: string): Observable<User> {
    return this.http
      .get<User>(`${environment.api}/confirm`, {
        params: {
          inUserId: userId,
          inToken: token,
        },
      })
      .pipe(this.saveUser);
  }

  resendConfirmationEmail(email: string): Observable<any> {
    return this.http.post(`${environment.api}/UserProfile/ResendEmailConfirmation`, {email: email});
  }

  logout() {
    this.clearAuthenticationData();
    this.router.navigate(["/auth/login"]);
  }

  clearAuthenticationData() {
    localStorage.removeItem(this.storageKey);
    this.currentUserSubject.next(null);
    this.currentUser = this.currentUserSubject.asObservable();
    this.onUserLogOut.emit();
  }

  isLogged(): boolean {
    return !!this.currentUserValue;
  }

  isAdmin(): boolean {
    const user = this.currentUserValue;

    if (user == null) {
      return false;
    }

    const isAdmin = user.IsAdmin;

    return this.isLogged() && isAdmin;
  }

  getExternalProfileInfo(id: string): Observable<ExternalProfileInfo> {
    return this.http.get<ExternalProfileInfo>(environment.api + "/externalprofile-information?id=" + id);
  }

  getUserLoginInfo(id: string): Observable<User> {
    return this.http.get<User>(environment.api + "/getuserlogininformation?token=" + id).pipe(this.saveUser);
  }

  getExternalProfileUserImage(id: string): Observable<any> {
    return this.http.get<any>(environment.api + "/externalprofileuserimage?id=" + id);
  }

  completeLinkingLogin(id: string, password: string): Observable<User> {
    return this.http
      .post<User>(environment.api + "/complete-linking-login", {
        Id: id,
        Password: password,
      })
      .pipe(this.saveUser);
  }

  removeExternalLogin(id: string): Observable<any> {
    return this.http.delete(environment.api + "/removeexternallogin?id=" + id);
  }

  getBearerTokenFromSso(exchangeKey: string) {
    return this.http.get<User>(environment.api + "/login/sso/exchange/" + exchangeKey).pipe(this.saveUser);
  }

  loginWithSso(email: string) {
    return this.http.post<void>(environment.api + "/login/sso", {
      Email: email,
    });
  }

  public HandleTokenExpiration(tokenExpirationDate) {
    if (this.currentUserValue?.SupportSessionExpirationDate && this.CheckIfSupportSessionIsExpired()) {
      clearInterval(this.intervalToCheckToken);
      this.logout();
    }

    const currentMoment = moment().utc();
    const momentExpirationDate = moment(tokenExpirationDate);

    const differenceBetweenDates = momentExpirationDate.diff(currentMoment);

    const minutesToExpire = differenceBetweenDates / 60000;

    if (minutesToExpire > 2 && minutesToExpire < 10) {
      this.refreshToken();
    }

    if (minutesToExpire <= 2) {
      clearInterval(this.intervalToCheckToken);
      this.logout();
    }
  }

  public CheckIfTokenIsExpired(): boolean {
    const expirationDate = this.currentUserValue?.TokenExpirationDate;

    if (!expirationDate) return true;

    const currentMoment = moment().utc();
    const momentExpirationDate = moment(expirationDate);

    const differenceBetweenDates = momentExpirationDate.diff(currentMoment);

    const minutesToExpire = differenceBetweenDates / 60000;

    return minutesToExpire <= 0;
  }

  public CheckIfSupportSessionIsExpired(): boolean {
    const expirationDate = this.currentUserValue?.SupportSessionExpirationDate;

    if (!expirationDate) return true;

    const currentMoment = moment().utc();
    const momentExpirationDate = moment(expirationDate);

    const differenceBetweenDates = momentExpirationDate.diff(currentMoment);

    const minutesToExpire = differenceBetweenDates / 60000;

    return minutesToExpire <= 0;
  }

  protected saveUser = pipe(
    tap((user: User) => {
      if (!user.AcceptingInvitation) {
        localStorage.setItem(this.storageKey, JSON.stringify(user));
        this.currentUserSubject.next(user);
        moment.tz.setDefault(user.TimezoneId);
      }
    }),
  );

  protected localSaveUser(user: User) {
    localStorage.setItem(this.storageKey, JSON.stringify(user));
    this.currentUserSubject.next(user);
  }
}

class TwoFactorNeededError extends Error {
  tfaAccessKey: string;

  constructor(message: string, {tfaAccessKey}: {tfaAccessKey: string}) {
    super(message);
    this.tfaAccessKey = tfaAccessKey;
  }
}

export const environment = {
  production: false,
  baseUrl: "",
  api: "/api",
  fullstoryOrgId: "KY2TX",
  fullstoryHost: "fsrelay.socialweaver.com",
  fullstoryScript: "fsrelay.socialweaver.com/s/fs.js",
  stripeKey: "pk_test_KovxyYaYqGk3fGbR62mzBXcj",
  cloudWatchKeyArn: "arn:aws:kms:us-east-1:070274887474:key/fbc2d0ef-9617-49a7-ac0b-b521b2667620",
  recaptchaSitekey: "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI",
  zapierClientId: "EV7OlqNbXY8uYMZdrqM2a1KTt5SYwQXf18LWXVYw",
  mediaCache: "https://stage-media.socialweaver.com/cache",
};
